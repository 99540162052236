
@tailwind base;
@tailwind components;
@tailwind utilities;


@layer components {
  .apple-header {
    @apply font-bold px-2 sm:px-4 lg:px-8 h-[65px] flex items-center text-slate-400 transition-all hover:text-white hover:border-b-2 hover:border-white;
  }
  .link {
    @apply relative hover:cursor-pointer transition-all duration-[700ms]
  }
  .link-emphasis {
    @apply hover:scale-[115%]
  }
  .link-underline-25 {
    @apply before:content-[''] before:w-[0%] before:h-[3px] before:absolute before:bottom-[5px] before:left-[-10%] hover:before:left-0 before:bg-homepage-alt before:transition-all before:duration-500 hover:before:w-[25%]
  }
  .link-underline-50 {
    @apply before:content-[''] before:w-[0%] before:h-[3px] before:absolute before:bottom-[5px] before:left-0 before:bg-homepage-alt before:transition-all before:duration-500 hover:before:w-[50%]
  }
  .link-underline-100 {
    @apply before:content-[''] before:w-[0%] before:h-[3px] before:absolute before:bottom-[5px] before:left-0 before:bg-homepage-alt before:transition-all before:duration-500 hover:before:w-[100%]
  }
  .dot-to-arrow {
    @apply before:transition-all before:duration-500 after:transition-all after:duration-500 before:absolute before:content-[''] before:bg-homepage-text before:w-[4px] before:h-[4px] before:right-6 before:top-[45%] before:rounded-full after:absolute after:content-[''] after:bg-homepage-text after:w-[4px] after:h-[4px] after:right-6 after:top-[45%] after:rounded-full before:rotate-[45deg] after:rotate-[-45deg] group-hover:before:translate-y-[-6.2px] group-hover:after:translate-y-[6.2px] group-hover:before:w-[20px] group-hover:after:w-[20px] group-hover:before:h-[3px] group-hover:after:h-[3px]
  }
  .homepage-website-imgs {
    @apply w-full h-auto shadow-lg rounded hover:scale-[103%]
  }

  .new-homepage-links {
    @apply font-['Oswald'] uppercase font-black italic text-[3rem] relative w-[max-content] z-20 w-full
    transition-all duration-500 after:transition-all after:duration-500
    hover:cursor-pointer hover:translate-x-[2rem] hover:!text-black hover:after:w-[80%]
    after:w-[0%] after:content-[''] after:bg-[#ccccff] after:h-[3px]
    after:left-0 after:bottom-[-5px] after:absolute
    group-hover:text-slate-300
  }

  .text-background {
    @apply absolute transition-all duration-500 top-0 left-0 w-screen h-screen
  }

  .new-homepage-bg-img {
    @apply w-screen h-screen absolute top-0 left-0 z-10 opacity-[10%]
  }

  .scrolling-text {
    @apply text-right font-['Oswald']
  }

  .marquee-wrapper {
    @apply transition-all duration-500 absolute top-0 right-12
  }

  .text-wrapper {
    @apply transition-all duration-500
  }
}

* {
  transition: color 0.5s ease;
  transition: background-color 0.5s ease;
  cursor: var(--cursor-type);
}

:root {

      --colorPalet-background: rgb(236 236 235 / 100%);
      --colorPalet-backgroundAlt: #fff;
      --colorPalet-text: #000;
      --colorPalet-textAlt: #fff;
      --colorPalet-header: #fff;
      --colorPalet-container: #fff;
      --colorPalet-containerAlt: rgb(38 57 77 / 50%);
      --colorPalet-mainLight : rgb(105 182 213);

      --cursor-type: default;

      --colorPallete-homepage-bg: rgb(255 255 255);
      --colorPallete-homepage-text: rgb(0 0 0);
      --colorPallete-homepage-alt: rgb(221 221 221);
}







/*maincontentbox*/
.contentBox {
  width: 100%;
  height: 100%;
}
@media screen(sm) {
  .contentBox {
    width: calc(100% - 180px) !important;
    height: calc(100% - 65px) !important;
  }
}

@media screen(md) {
  .contentBox {
    width: calc(100% - 240px) !important;
    height: calc(100% - 65px) !important;
  }
}

.sideBar {
  height: auto;
}

@media screen(sm) {
  .sideBar {
    height: calc(100% - 65px);
    top: 65px;
  }
}

#sectionContent {
  height: calc(100% - 180px)
}

@media screen(sm) {
  #sectionContent {
    height: calc(100% - 65px)
  }
}





#sectionContent::-webkit-scrollbar {
  display: none;
}
#sectionContent {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}



code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.modal-content {
  background-color: var(--colorPalet-container);
}

.modal {
  background-color: var(--colorPalet-containerAlt);
}

.colorPaletteBackground{
  transition: background-color 2s ease;
  top: 0;
  left: 0;
  background-color: rgb(100 100 100 / 60%);
}

.colorPaletteBackground:hover {
  cursor: pointer;
  background-color: rgb(100 100 100 / 30%);
}

.dropdown-menu {
  background-color: var(--colorPalet-container);
}

.bg-light {
  background-color: var(--colorPalet-header);
}

.bg-white {
  background-color: var(--colorPalet-container);
}

.navbar-light, .navbar-brand, .navbar-nav, .nav-link, p, h1, h2, h3, h4, h5, body {
  color: var(--colorPalet-text);
}

.containerShadow {
  box-shadow: var(--colorPalet-containerAlt) 0px 20px 30px -10px;
}

.dropdown-menu {
  min-width: 15rem;
}

.nav-link-home:hover {
    font-weight: bold
}

  .notificationEntry {
    border-radius: 5px;

  }

  .notificationEntry:hover {
    filter: drop-shadow(0 0 0.25rem rgba(0, 0, 0, 0.2));
  }


  .notificationEntryNew {
    background: var(--colorPalet-background);
    cursor: pointer;
  }

  .notificationEntryNew::before{
       content: '';
       display: flex;
       width: 15px;
       height: 15px;
       -moz-border-radius: 7.5px;
       -webkit-border-radius: 7.5px;
       border-radius: 7.5px;
       background-color: var(--colorPalet-mainLight);
       margin: 5px;
       float: right;
    }

  .notificationEntrySeen {
    background: var(--colorPalet-container);
    cursor:pointer
  }
    .icon-button__badge {
      position: absolute;
      top: -2.5px;
      right: -2.5px;
      width: 15px;
      height: 15px;
      background: var(--colorPalet-mainLight);
      color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
    }

    #colorPaletteModal {
      right: 0;
      top: 0.5rem;
      z-index: 2000;
      width: 35%;
      transition: visiblity 1s ease;
    }

    .colorPaletteSelection {

      transition: box-shadow 1s ease;
      transition: font-size 1s ease;
    }

    .colorPaletteSelection:hover {

      box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
      cursor: pointer;
      font-size: 2rem;
      transition: box-shadow 1s ease;
      transition: font-size 1s ease;
    }

    .popupBlockNotification {
      background: var(--colorPalet-container);
    }



        .loader .advert-title-placeholder,
        .loader-content {
          animation: flash 2s infinite;
        }

        .loader .advert-content-placeholder {
          animation: flash 2s infinite;
        }

        @keyframes flash {
          0% {
            color: var(--loader1-color);
            background: var(--loader1-color);
          }

          50% {
            color: var(--loader2-color);
            background: var(--loader2-color);
          }

          100% {
            color: var(--loader1-color);
            background: var(--loader1-color);
          }
        }

        .advert-content {
          background: var(--colorPalet-containerAlt);
        }
        h2 {
          margin-bottom: 2rem;
        }


        .mouse {
        	 width: var(--size);
        	 height: var(--size);
        	 border-radius: 50%;
        	 background: white;
        	 position: fixed;
        	 z-index: 6000;
        	 pointer-events: none;
        	 mix-blend-mode: exclusion;
        }
         .mouse-follow {
        	 width: var(--size);
        	 height: var(--size);
        	 position: fixed;
        	 top: 0;
        	 left: 0;
        	 background-image: url("data:image/svg+xml,%3Csvg width='47' height='47' viewBox='0 0 47 47' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M42.4202 42.4202C38.8403 46 33.3594 46 23.5 46C13.6406 46 8.15966 46 4.57983 42.4202C1 38.8403 1 33.3594 1 23.5C1 13.6406 1 8.15966 4.57983 4.57983C8.15966 1 13.6406 1 23.5 1C33.3594 1 38.8403 1 42.4202 4.57983C46 8.15966 46 13.6406 46 23.5C46 33.3594 46 38.8403 42.4202 42.4202Z' stroke='white'/%3E%3C/svg%3E%0A");
        	 background-size: cover;
        	 mix-blend-mode: exclusion;
        	 pointer-events: none;
        	 opacity: .5;
        	 z-index: 6000;
        }



        .typing-intro {
          animation: typing 2s steps(19), blink .5s step-end infinite alternate;
        }

        @keyframes typing {
          from {
            width: 0
          }
        }

        @keyframes blink {
          50% {
            border-color: transparent
          }
        }


  .popupBlockMain {
    background: #4d4761;
    color: #E7FFF6;

  }











  section {

    animation: floating 5s cubic-bezier(0.37, 0, 0.63, 1) infinite;
  }

  .tilt {
    display: flex;
    justify-content: center;
    align-items: center;
    animation: tilting 10s cubic-bezier(0.37, 0, 0.63, 1) infinite;
  }

  section span {
    transition: font-weight 0.4s ease-in;
    animation: floating-secondary 2.5s cubic-bezier(0.37, 0, 0.63, 1) infinite;
  }

  section span:nth-child(1) {
    animation-delay: 1s;
  }
  section span:nth-child(2) {
    animation-delay: 2s;
  }
  section span:nth-child(3) {
    animation-delay: 3s;
  }
  section span:nth-child(4) {
    animation-delay: 4s;
  }
  section span:nth-child(5) {
    animation-delay: 5s;
  }
  section span:nth-child(6) {
    animation-delay: 6s;
  }
  section span:nth-child(7) {
    animation-delay: 7s;
  }

  @keyframes floating {
    0% {
      transform: translate(0, 0);
    }
    50% {
      transform: translate(0, 5vh);
    }
    100% {
      transform: translate(0, 0);
    }
  }

  @keyframes floating-secondary {
    0% {
      transform: translate(0, 0);
    }
    50% {
      transform: translate(0, 1vh);
    }
    100% {
      transform: translate(0, 0);
    }
  }

  @keyframes tilting {
    0% {
      transform: rotate(1deg);
    }
    50% {
      transform: rotate(-1deg);
    }
    100% {
      transform: rotate(1deg);
    }
  }



@layer components {
  .btn-primary {
    @apply ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium;
  }
}

.btn-primary {
  background-color: var(--colorPalet-header) !important;
  color: var(--colorPalet-text) !important;
  border-color: var(--colorPalet-containerAlt) !important;
  transition: background-color 1s ease;
}

.btn-primary:hover {
  background-color: var(--colorPalet-text) !important;
  color: var(--colorPalet-header) !important;
  border-color: var(--colorPalet-text) !important;
}

.notificationButton {
  background: var(--colorPalet-background);
}
.notificationButton:hover {
  cursor: pointer !important;
  background: var(--colorPalet-container);
}
.notificationButton:active {
  background: var(--colorPalet-container);
}

.colorPaletteButton {
  background: var(--colorPalet-background);
}

.colorPaletteButton:hover {
  cursor: pointer;
  background: var(--colorPalet-container);
}

.colorPaletteButton:active {
  background: var(--colorPalet-container);
}

.popupBlockColor {
  background: var(--colorPalet-container);
}

#body {
  scroll-snap-type: y mandatory;
  overflow-y: scroll;
}

.activeSectionTitle {
  color: white;
  border-bottom: 2px solid white;
}

#developerAnimation:before {
  animation: sideToSideBefore 1s infinite alternate;
}

@keyframes sideToSideBefore {
  from {
    left: -0.5rem
  }
}

#developerAnimation:after {
  animation: sideToSideAfter 1s infinite alternate;
}

@keyframes sideToSideAfter {
  from {
    right: 0rem;
  }
}

#designerBorder:before {
  animation: borderResize 2s infinite alternate;
}

@keyframes borderResize {
  from {
    width: calc(100% + 1rem);
    padding: 1.5rem;
  }
}

#designerCursor {
  animation: cursorResize 2s infinite alternate;
}

@keyframes cursorResize {
  from {
    right: -2rem;
    padding-top: 1rem;
  }
}

.marquee-wrapper {
  height: 100vh;
  width: 100%;
  overflow: hidden;
}
.text-wrapper {
  animation-name: vertical-marquee;
  animation-duration: 15s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  font-size: 1.5rem;
}
@keyframes vertical-marquee {
  from {transform: translateY(0%)}
  to {transform: translateY(-100%)}
}
